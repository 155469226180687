import {API} from '@indieocean/apidef'
import Link from 'next/link'
import {useRouter} from 'next/router'
import React, {useEffect} from 'react'
import {AppPage} from '../Common/Page/AppPage'
import {IntercomLauncher} from '../Common/Tools/IntercomLauncher'
import {useAppPathFn} from '../Common/Tools/UseAppPathFn'
import {useUser} from '../Common/WithUser'
import {useStrictMemo} from '../Utils/UseStrictMemo'

export const Index = React.memo(() => {
  const user = useUser()
  const hasStore = (user?.store?.data ?? null) !== null
  const router = useStrictMemo(useRouter(), [])
  const pathFn = useAppPathFn()
  useEffect(() => {
    if (hasStore) void router.replace(pathFn(API.Path.home))
  }, [hasStore, pathFn, router])

  return hasStore ? <></> : <_Static />
})

const _Static = React.memo(() => (
  <AppPage
    title="IndieOcean"
    header={null}
    nav={null}
    showGoogleSignInPrompt={false}
  >
    <_StaticBody />
  </AppPage>
))

const _StaticBody = React.memo(() => {
  const pathFn = useAppPathFn()
  useEffect(() => {
    ;(window as any).Intercom('update', {hide_default_launcher: false})
  }, [])
  return (
    <div className="body-grid3-wide">
      <IntercomLauncher mode="fixed" hasNav={false} />
      <div className="body-grid-content flex items-center">
        <Link href={pathFn(API.Path.login())}>
          <a className="fixed right-0 top-0 mr-3 mt-3 a-btn btn-dark btn-lg">
            Login
          </a>
        </Link>

        <div className="">
          <div className="mb-16 ">
            <img
              className="block mb-10"
              src="/logotype.png"
              width={2916}
              height={319}
              style={{width: '300px', height: 'auto'}}
            />
            <h1 className="text-4xl md:text-5xl font-bold">
              Create a personal bookstore.
            </h1>
            <h1 className="text-4xl md:text-5xl font-bold mt-2">Instantly.</h1>
            <p className="  text-xl font-karla mt-2">
              Your own online bookstore! Where you pick the books and get paid
              from the sales. IndieOcean runs the store for you. We take care of
              setting up the website, stocking, fullfulling orders, taxes,
              customer service. Everything! You just curate the books.
            </p>
            <div className="mt-10">
              <Link href={pathFn(API.Path.vision)}>
                <a className="text-xl font-bold underline">Our vision</a>
              </Link>
            </div>
            <div className="mt-6">
              <Link href={pathFn(API.Path.explore)}>
                <a className="text-xl font-bold underline">Explore Stores</a>
              </Link>
            </div>
            <Link href={pathFn(API.Path.getStarted())}>
              <a className="a-btn text-2xl pt-2.5 pb-2.5 pl-6 pr-6  btn-dark mt-10">
                Create Your Store
              </a>
            </Link>
          </div>
          <div className="fixed bottom-2 left-0 flex w-full justify-center items-center">
            <Link href={pathFn(API.Path.terms.root)}>
              <a className="">Terms and Privacy Policy</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
})
